<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="5">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      title="1. Hệ đào tạo"
                      label-for="trainingSystemSelect"
                    >
                      <v-select
                        v-model="trainingSystemSelect"
                        :options="trainingSystemsOptions"
                        :reduce="(option) => option.value"
                        placeholder="1. Hệ đào tạo"
                        @input="onChangeTrainingSystem"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      title="2. Khóa - Học kỳ"
                      label-for="coursesSemestersSelected"
                    >
                      <v-select
                        v-model="coursesSemestersSelected"
                        :options="coursesSemestersOptions"
                        :reduce="(option) => option.value"
                        placeholder="2. Khóa - Học kỳ"
                        @input="onChangeCoursesSemester"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      title="Dự kiến thi"
                      label-for="examinationScheduler"
                    >
                      <v-select
                        v-model="examinationSchedulerSelect"
                        vid="examinationScheduler"
                        :options="examinationSchedulers"
                        :reduce="(option) => option.value"
                        placeholder="3. Dự kiến thi"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      title="4. Môn học"
                      label-for="subjectSelect"
                    >
                      <v-select
                        v-model="courseSemesterSubjectSelect"
                        :options="courseSemesterSubjectOptions"
                        :reduce="(option) => option.value"
                        placeholder="4. Môn học"
                        @input="onChangeCourseSemesterSubject"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-cols="1"
                  label="Lớp tín chỉ:"
                  label-for="listCreditClass"
                >
                  <span
                    id="listCreditClass"
                    class="float-left"
                    v-html="showCreditClassList(listCreditClass)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="4"
                md="3"
              >
                <b-form-group
                  title="Ngày bắt đầu"
                  label-for="startDate"
                >
                  <b-form-datepicker
                    id="startDate"
                    v-model="startDate"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="vi"
                    format="dd/mm/yyyy"
                    placeholder="Ngày bắt đầu"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="2"
              >
                <b-form-group
                  title="Số lượng phòng"
                >
                  <b-form-input
                    v-model="countRoom"
                    :disabled="isCountStudent === 0 || isExistExaminationRoom > 0 || !creatable"
                    :step="1"
                    min="1"
                    type="number"
                    class="text-bold text-danger"
                    placeholder="Số lượng phòng"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="2"
              >
                <b-form-checkbox
                  v-model="isThiLai"
                  class="mt-1"
                  value="1"
                  unchecked-value="0"
                  @input="handleChecked"
                >
                  Thi lại
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                md="5"
              >
                <div class="text-right">
                  <b-button
                    v-show="isCountStudent > 0 && isExistExaminationRoom === 0 && countRoom > 0 && creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onCreateExaminationRoom"
                  >
                    <span class="text-nowrap text-right">Tạo phòng thi</span>
                  </b-button>
                  <b-button
                    v-show="isExistExaminationRoom !== 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="onSelectExaminationRoom"
                  >
                    <span class="text-nowrap text-right">
                      Danh sách phòng
                    </span>
                  </b-button>
                  <b-button
                      v-if="examinationSchedulerSelect && isThiLai != 1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="mr-1"
                      @click="exportExcelExaminationScheduler"
                    >
                    <span
                      class="text-nowrap text-right"
                    >Xuất lịch thi học kỳ</span>
                  </b-button>
                  <b-dropdown
                    v-show="examinationRooms.length > 0"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    text="Xuất PDF"
                    right
                    variant="outline-primary"
                  >
                    <b-dropdown-item @click="exportPdfStudentsExaminationRoom">
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FileIcon" /> In DS phòng thi
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="exportPdfStudentsExaminationScoreFile">
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FileIcon" /> In phiếu ghi điểm SBD
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="examinationRooms"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  line-numbers
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          v-b-modal.examinationRoomSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          @click="onEditExaminationRoom(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-dropdown
                          right
                          variant="success"
                        >
                          <b-dropdown-item
                            v-if="studentExaminationRoomReadable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onShowsStudentExaminationRoom(props.row)"
                          >
                            <feather-icon icon="UsersIcon" />
                            Danh sách sinh viên
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="examInvigilatorReadable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onAssignExamInvigilator(props.row.id)"
                          >
                            <feather-icon icon="UsersIcon" />
                            Phân công coi thi
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="studentExaminationPointUpdatable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onShowStudentExaminationPoint(props.row)"
                          >
                            <feather-icon icon="EditIcon" />
                            Nhập điểm thi theo phách
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-group>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <examinationRoomSave
      ref="examinationRoomsSaveFormRef"
      :examination-room="currentExaminationRoom"
      @succeed="onSucceed"
    />

    <b-modal
      id="studentExaminationPointModal"
      centered
      :title="`Nhập điểm thi theo phách: ${(currentExaminationRoom)?currentExaminationRoom.name : ''}`"
      size="xl"
      body-class="position-static"
      no-close-on-backdrop
      hide-footer
    >
      <StudentExaminationPoint
        :examination-room-id="currentExaminationRoomId"
        :training-system-id="trainingSystemSelect"
        :course-semester-id="coursesSemestersSelected"
        :subject-id="selectedSubjectId"
      />
    </b-modal>
    <b-modal
      id="studentExaminationRoomModal"
      centered
      :title="`Danh sách sinh viên: ${(currentExaminationRoom)?currentExaminationRoom.name : ''}`"
      size="xl"
      body-class="position-static"
      no-close-on-backdrop
      hide-footer
    >
      <StudentExaminationRoom
        :ext-data="currentExaminationRoom"
      />
    </b-modal>
    <ExaminationRoomTeacher
      :examination-room-id="currentExaminationRoomId"
      :subject-id="selectedSubjectId"
    />
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BFormInput,
  BRow,
  BModal, BFormDatepicker,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import examinationRoomSave from '@/views/examination-room/ExaminationRoomSave.vue'
import vSelect from 'vue-select'
import StudentExaminationPoint from '@/views/student-examination-room/StudentExaminationPoint.vue'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import StudentExaminationRoom from '@/views/student-examination-room/StudentExaminationRoom.vue'
import ExaminationRoomTeacher from '@/views/examination-room/examination-room-teacher/ExaminationRoomTeacher.vue'
import { getUser } from '@/auth/utils'

export default {
  name: 'ExaminationRoom',
  directives: {
    Ripple,
  },
  components: {
    ExaminationRoomTeacher,
    StudentExaminationPoint,
    StudentExaminationRoom,
    examinationRoomSave,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormInput,
    BModal,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      trainingSystemSelect: undefined,
      coursesSemestersSelected: undefined,
      courseSemesterSubjectSelect: undefined,
      examinationSchedulerSelect: undefined,
      listCreditClass: undefined,
      startDate: undefined,
      isExistExaminationRoom: 0,
      isCountStudent: 0,
      isThiLai: 0,
      isLoading: false,
      countRoom: 1,
      filter: {
        organizationId: getUser().orgId,
        currentPage: 1,
        itemsPerPage: 10,
        code: null,
        name: null,
        subject_id: null,
        status: null,
        sort: null,
        again: 0,
        separate: 0,
      },
      currentExaminationRoom: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên phòng thi theo môn học',
          field: 'name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Địa điểm thi',
          field: 'room',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên môn học',
          field: 'subject',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giám thị',
          field: 'supervisorNum',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Bắt đầu',
          field: 'start_at',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Kết thúc',
          field: 'end_at',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      currentExaminationRoomId: null,
    }
  },
  computed: {
    ...mapGetters({
      examinationRooms: 'examinationRoom/examinationRooms',
      totalRows: 'examinationRoom/totalRows',
      allTrainingSystems: 'examinationRoom/allTrainingSystems',
      coursesSemesters: 'examinationRoom/coursesSemesters',
      courseSemesterSubject: 'examinationRoom/listCourseSemesterSubject',
      courseSemesterSubjectThiLai: 'examinationRoom/listCourseSemesterSubjectThiLai',
      examinationSchedulers: 'dropdown/examinationSchedulers',
      examinationSchedulerSubjects: 'dropdown/examinationSchedulerSubjects',
      examinationSchedulerSubjectDates: 'dropdown/examinationSchedulerSubjectDates',
      dataExaminationSchedulerExport: 'examinationRoom/dataExaminationSchedulerExport',
    }),
    coursesSemestersOptions() {
      return this.coursesSemesters.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    trainingSystemsOptions() {
      return this.allTrainingSystems.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    courseSemesterSubjectOptions() {
      return this.courseSemesterSubject.map(item => ({
        value: item.code,
        label: item.name,
      }))
    },
    studentExaminationPointUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.EXAMINATION_POINT)
    },
    examInvigilatorReadable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.EXAMINATION_ROOM_TEACHER)
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.EXAMINATION_ROOM)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.EXAMINATION_ROOM)
    },
    studentExaminationRoomReadable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.STUDENT_EXAMINATION_ROOM)
    },
    selectedSubjectId() {
      // eslint-disable-next-line eqeqeq
      try {
        // eslint-disable-next-line eqeqeq
        return this.courseSemesterSubject.find(e => e.code == this.courseSemesterSubjectSelect).id || 0
      } catch (e) {
        return 0
      }
    },
    isSeparateRoom() {
      try {
        // eslint-disable-next-line eqeqeq
        return (this.courseSemesterSubjectSelect.substr(-9) === '_THCLASS_') ? 1 : 0
      } catch (e) {
        return 0
      }
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({ organizationId: this.user.orgId })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getAllRooms: 'examinationRoom/getAllRooms',
      getAllSubjects: 'examinationRoom/getAllSubjects',
      getTrainingSystemsByOrganizationId: 'examinationRoom/getTrainingSystemsByOrganizationId',
      getExaminationRooms: 'examinationRoom/getExaminationRooms',
      deleteExaminationRoom: 'examinationRoom/deleteExaminationRoom',
      getCourseSemesters: 'examinationRoom/getCourseSemesters',
      getCourseSemesterSubjectSelect: 'examinationRoom/getCourseSemesterSubject',
      getCourseSemesterSubjectSelectThiLai: 'examinationRoom/getCourseSemesterSubjectThiLai',
      insertExaminationRoom: 'examinationRoom/insertExaminationRooms',
      getExaminationSchedulers: 'dropdown/getExaminationSchedulers',
      getExaminationSchedulerSubjects: 'dropdown/getExaminationSchedulerSubjects',
      exportStudentsExaminationRoom: 'examinationRoom/exportPdf',
      exportPdfStudentsExaminationScore: 'studentExaminationRoom/exportPdfStudentsExaminationScore',
      getDataExaminationSchedulerExport: 'examinationRoom/downloadExaminationSchedulerExport',
    }),
    async onChangeTrainingSystem() {
      if (this.trainingSystemSelect == null) {
        this.reset()
        this.$store.commit('examinationRoom/SET_COURSESSEMESTERS', {
          data: [],
        })
        this.$store.commit('examinationRoom/SET_COURSE_SEMESTER_SUBJECT', {
          data: [],
        })
        this.coursesSemestersSelected = null
        this.courseSemesterSubjectSelect = null
      } else {
        await this.getCourseSemesters({
          organizationId: getUser().orgId,
          training_system_id: this.trainingSystemSelect,
        })
      }
    },
    async onChangeCoursesSemester() {
      this.examinationSchedulerSelect = null
      if (this.coursesSemestersSelected == null) {
        this.reset()
        this.$store.commit('examinationRoom/SET_COURSE_SEMESTER_SUBJECT', {
          data: [],
        })
        this.courseSemesterSubjectSelect = null
      } else {
        // await this.getCourseSemesterSubjectSelect({ courses_semester_id: this.coursesSemestersSelected });
        await Promise.all([
          this.getCourseSemesterSubjectSelect({ organizationId: getUser().orgId, courses_semester_id: this.coursesSemestersSelected, hasExam: 1 }),
          this.getExaminationSchedulers({ organizationId: getUser().orgId, courseSemesterId: this.coursesSemestersSelected }),
        ])
        if (this.examinationSchedulers.length > 0) {
          this.examinationSchedulerSelect = this.examinationSchedulers[0].value
        }
      }
    },
    async onChangeCourseSemesterSubject() {
      let item = null

      if (this.courseSemesterSubjectSelect == null) {
        this.reset()
      } else {
        // eslint-disable-next-line eqeqeq
        if (this.isThiLai == 1) {
          // todo
          await this.getCourseSemesterSubjectSelectThiLai({
            organizationId: getUser().orgId,
            courses_semester_id: this.coursesSemestersSelected,
            subject_id: this.selectedSubjectId,
          })
          item = this.courseSemesterSubjectThiLai.find(
            x => x.code === this.courseSemesterSubjectSelect,
          )
        } else {
          item = this.courseSemesterSubject.find(
            x => x.code === this.courseSemesterSubjectSelect,
          )
        }
        if (item) {
          this.listCreditClass = item.list_credit_class
          this.isExistExaminationRoom = item.count_examination_room
          this.isCountStudent = item.count_student
          if (this.isCountStudent > 0) {
            this.countRoom = item.list_credit_class.split(',').length
          }
        }
        this.filter.subject_id = this.selectedSubjectId
        this.$store.commit('examinationRoom/SET_EXAMINATIONROOMS', {
          total: 0,
          records: [],
        })

        // Du kien lich thi
        if (this.examinationSchedulerSelect) {
          this.startDate = undefined
          await this.getExaminationSchedulerSubjects({
            organizationId: getUser().orgId,
            examinationSchedulerId: this.examinationSchedulerSelect,
            subjectId: this.selectedSubjectId,
          })
          if (this.examinationSchedulerSubjectDates.length > 0) {
            if (this.isSeparateRoom) {
              this.startDate = this.examinationSchedulerSubjectDates.find(e => e.isSeparateClass === 1)?.label
            } else {
              this.startDate = this.examinationSchedulerSubjectDates[0].label
            }
          }
        }
      }
    },
    reset() {
      this.listCreditClass = null
      this.isExistExaminationRoom = 0
      this.isCountStudent = 0
      this.$store.commit('examinationRoom/SET_EXAMINATIONROOMS', {
        total: 0,
        records: [],
      })
    },
    async onSelectExaminationRoom() {
      this.isLoading = true
      try {
        this.filter.again = this.isThiLai
        this.filter.separate = this.isSeparateRoom
        await this.getExaminationRooms(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onCreateExaminationRoom() {
      if (!this.startDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ngày bắt đầu không được trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const roomNum = this.countRoom
      const subjectName = this.courseSemesterSubjectOptions.find(e => e.value === this.courseSemesterSubjectSelect).label
      this.$swal({
        title: `Bạn chắc chắn muốn tạo <span class="text-danger">${roomNum}</span> phòng thi theo môn học <span class="text-danger">${subjectName}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.insertExaminationRoom({
              courses_semester_id: this.coursesSemestersSelected,
              subject_id: this.selectedSubjectId,
              count_room: this.countRoom,
              start_date: this.startDate,
              p_again: this.isThiLai,
              is_separate_room: this.isSeparateRoom,
            })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.isExistExaminationRoom = 1
                this.filter.subject = this.selectedSubjectId
                this.filter.separate = this.isSeparateRoom
                // await this.getExaminationRooms(this.filter)
                await this.onChangeCoursesSemester()
                await this.onChangeCourseSemesterSubject()
                await this.getExaminationRoomsFromStore()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onEditExaminationRoom(examinationRoom) {
      const {
        rowNum, originalIndex, vgt_id, ...rest
      } = examinationRoom
      this.currentExaminationRoom = rest
    },
    onDeleteExaminationRoom(examinationRoom) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa phòng thi theo môn học <span class="text-danger">${examinationRoom.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteExaminationRoom(
              examinationRoom.id,
            )
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getExaminationRooms(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getExaminationRoomsFromStore()
    },
    async getExaminationRoomsFromStore() {
      this.isLoading = true
      try {
        await this.getExaminationRooms(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getExaminationRoomsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'code') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getExaminationRoomsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'room')) this.updateParams({ room: columnFilters.room })
      if (Object.hasOwn(columnFilters, 'subject')) this.updateParams({ subject: columnFilters.subject })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getExaminationRoomsFromStore()
    },
    async onSucceed() {
      await this.getExaminationRoomsFromStore()
    },

    convertMysql2VnDate(rawDate) {
      const tmpDate = rawDate.split('-')
      return `${tmpDate[2]}-${tmpDate[1]}-${tmpDate[0]}`
    },
    showCreditClassList(creditClass) {
      let htmlList = '<ol class="list list-inline mb-0">'
      try {
        const tmpClasses = creditClass.split(',')
        tmpClasses.forEach(e => {
          htmlList += `<li class="list-inline-item"><span class="badge badge-success">${e}</span></li>`
        })
        htmlList += '</ol>'
      } catch (e) {
        htmlList += '<li class="list-inline-item"><span class="badge badge-dark">Không có dữ liệu</span></li>'
      }
      const htmlCountStudent = (this.isCountStudent) ? `(<u><strong class="text-danger h4">${this.isCountStudent}</strong> Thí sinh</u>)` : ''
      return `${htmlList} ${htmlCountStudent}`
    },
    onShowStudentExaminationPoint(row) {
      const {
        rowNum, originalIndex, vgt_id, ...rest
      } = row
      this.currentExaminationRoom = rest
      this.currentExaminationRoomId = rest.id
      this.$bvModal.show('studentExaminationPointModal')
    },
    onShowsStudentExaminationRoom(row) {
      const { originalIndex, vgt_id, ...rest } = row
      this.currentExaminationRoom = { ...rest }
      this.$bvModal.show('studentExaminationRoomModal')
    },
    onAssignExamInvigilator(id) {
      this.currentExaminationRoomId = id
      this.$bvModal.show('examinationRoomTeacherModal')
    },
    async exportPdfStudentsExaminationRoom() {
      if (this.examinationRooms.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportStudentsExaminationRoom({
          courseSemesterId: this.coursesSemestersSelected,
          subjectId: this.selectedSubjectId,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportPdfStudentsExaminationScoreFile() {
      if (this.examinationRooms.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportPdfStudentsExaminationScore({
          courseSemesterId: this.coursesSemestersSelected,
          subjectId: this.selectedSubjectId,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async handleChecked() {
      let item = null
      if (this.courseSemesterSubjectSelect == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa chọn môn học xét thi lại!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } else {
        // eslint-disable-next-line eqeqeq
        if (this.isThiLai == 1) {
          await this.getCourseSemesterSubjectSelectThiLai({
            organizationId: getUser().orgId,
            courses_semester_id: this.coursesSemestersSelected,
            subject_id: this.selectedSubjectId,
          })
          item = this.courseSemesterSubjectThiLai.find(
            x => x.code === this.courseSemesterSubjectSelect,
          )
        } else {
          item = this.courseSemesterSubject.find(
            x => x.code === this.courseSemesterSubjectSelect,
          )
        }
        if (item) {
          this.listCreditClass = item.list_credit_class
          this.isExistExaminationRoom = item.count_examination_room
          this.isCountStudent = item.count_student
        }
        this.filter.subject_id = this.selectedSubjectId
        this.filter.separate = this.isSeparateRoom
        this.$store.commit('examinationRoom/SET_EXAMINATIONROOMS', {
          total: 0,
          records: [],
        })

        // Du kien lich thi
        if (this.examinationSchedulerSelect) {
          this.startDate = undefined
          await this.getExaminationSchedulerSubjects({
            organizationId: getUser().orgId,
            examinationSchedulerId: this.examinationSchedulerSelect,
            subjectId: this.selectedSubjectId,
          })

          if (this.examinationSchedulerSubjectDates.length > 0) {
            if (this.courseSemesterSubjectSelect.substr(-9) === '_THCLASS_') {
              this.startDate = this.examinationSchedulerSubjectDates.find(e => e.isSeparateClass === 1).label
            } else {
              this.startDate = this.examinationSchedulerSubjectDates[0].label
            }
          }
        }
      }
    },
    async exportExcelExaminationScheduler() {
      this.isLoading = true
      try {
        await this.getDataExaminationSchedulerExport({
          trainingSystemId: this.trainingSystemSelect,
          courseSemesterId: this.coursesSemestersSelected,
          examinationSchedulerId: this.examinationSchedulerSelect,
          subjectCode: this.courseSemesterSubjectSelect,
        })

        const a = document.createElement('a')
        document.body.appendChild(a)
        const blob = new Blob([this.dataExaminationSchedulerExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        let coursesSemester = this.coursesSemestersOptions.find(coursesSemester => coursesSemester.value === this.coursesSemestersSelected)?.label || ""
        a.download = `Lich_thi_hoc_ky_${ coursesSemester }.xlsx`
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
